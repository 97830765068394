<template>
  <v-container
    v-if="!addTemplatePage && !addDocumentsToCaseWorkbenchPage && !addRequiredDocumentsToWorkbenchMobilePage"
    fluid
    style="height: 100%; width: 100%; background-color: white;"
    class="pa-0"
  >
    <v-toolbar
      flat
      width="100%"
      class="toolbar"
    >
      <v-btn
        icon
        rounded
        @click.stop="$router.back()"
      >
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ workbench.name }}
      </v-toolbar-title>
    </v-toolbar>

    <v-list
      expand
      class="white pb-16"
    >
      <v-list-group
        v-for="(item, i) in workbenchSectionsMobile"
        :key="i"
        v-model="item.active"
        :prepend-icon="item.action"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title
              style="white-space: normal;"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          class="px-0"
        >
          <!-- WORKBENCH TEMPLATES -->
          <v-card
            v-if="item.isTemplates"
            flat
            min-height="100"
            width="100%"
          >
            <v-card-text
              :class="!workbench.templates.length ? 'mb-16 pb-8' : ''"
            >
              <v-row
                v-if="workbench && account.accountType === 'lawyer'"
                align="center"
              >
                <div
                  v-for="temp in workbench.templates"
                  :key="temp._id"
                  class="mx-2 my-4"
                >
                  <TemplateCardCases
                    :template="temp"
                    :workbench="workbench"
                    :case-data="caseData"
                    @update-template-name="updateTemplateName(workbench, temp)"
                    @share-with-client="shareWithClient(workbench, temp)"
                    @stop-sharing-with-client="stopSharingWithClient(workbench, temp)"
                    @delete="deleteWorkbenchTemplate(workbench, temp)"
                  />
                </div>
              </v-row>
              <v-row
                v-if="account.accountType !== 'lawyer'"
                align="center"
              >
                <div
                  v-for="temp in workbench.templates"
                  :key="temp._id"
                  class="mx-2 my-4"
                >
                  <TemplateCardCases
                    v-if="temp.sharedWithClient"
                    :template="temp"
                    :workbench="workbench"
                    @share-with-client="shareWithClient(workbench, temp)"
                    @stop-sharing-with-client="stopSharingWithClient(workbench, temp)"
                    @delete="deleteWorkbenchTemplate(workbench, temp)"
                  />
                </div>
              </v-row>
              <div
                v-if="!workbench.templates.length"
                class="center"
                style="font-size: 15px;"
              >
                {{ $t('expressions|no_actual_workbench_templates') }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                v-if="account.accountType === 'lawyer'"
                x-small
                rounded
                color="primary"
                @click="addTemplatePage = true"
              >
                <v-icon
                  x-small
                  class="mr-1"
                >
                  mdi-plus
                </v-icon>
                {{ $t('actions|add_new_template') }}
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>

          <!-- WORKBENCH DOCUMENTS -->
          <v-card
            v-if="item.isWorkbenchDocuments"
            flat
            min-height="100"
            width="100%"
          >
            <v-card-text
              class="pa-0"
              :class="!workbench.assignedDocuments.length ? 'mb-16 pb-8' : ''"
            >
              <document-list
                v-if="workbench.assignedDocuments && workbench.assignedDocuments.length"
                :workbench-documents="workbench.assignedDocuments"
                :toolbar="null"
                :is-flat="true"
                :in-case="true"
                :right-panel-workbench="true"
                :width="'100%'"
                @newWorkbenchDocument="onNewWorkbenchDocument"
                @dragStartWorkbenchDocuments="dragArea = true"
                @dragEndWorkbenchDocuments="dragArea = false"
                @click="openDocument"
                @delete="(doc) => removeWorkbenchDocument({ workbench, file: doc })"
              />
              <div
                v-else
                class="center"
                style="font-size: 15px;"
              >
                {{ $t('expressions|no_actual_workbench_documents') }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                x-small
                rounded
                color="primary"
                @click="triggerAction('assignWorkbenchDocument')"
              >
                <v-icon
                  x-small
                  class="mr-2"
                >
                  mdi-link
                </v-icon>
                {{ $t('actions|assign_document') }}
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>

          <!-- REQUIRED WORKBENCH DOCUMENTS -->
          <v-card
            v-if="item.isRequiredWorkbenchDocuments"
            flat
            min-height="100"
            width="100%"
          >
            <v-card-text
              class="pa-0"
              :class="!workbench.requiredDocuments.length ? 'mb-16 pb-12' : ''"
            >
              <draggable
                v-if="workbench.requiredDocuments && workbench.requiredDocuments.length"
                :list="workbench.requiredDocuments"
                group="wbReqDocs"
                class="dragArea list-group"
                ghost-class="ghost"
                @start="onStart"
                @end="onEnd"
                @change="onChangeReqDocs"
              >
                <div
                  v-for="(suggestion, index) in workbench.requiredDocuments"
                  :key="index"
                >
                  <v-card
                    flat
                    rounded
                    outlined
                    class="my-4 mx-2 d-flex rounded-lg"
                  >
                    <v-row
                      no-gutters
                    >
                      <v-col
                        cols="2"
                      >
                        <v-card
                          class="rounded-r-0"
                          flat
                          height="100%"
                          color="lightBackground"
                          :style="`border-top-left-radius: 10px; border-bottom-left-radius: 10px; border: 1px solid ${$vuetify.theme.themes.light.background};`"
                        >
                          <v-icon
                            color="primary"
                            class="handle-icon center"
                            style="cursor: grab;"
                            size="34"
                          >
                            {{ icons.drag }}
                          </v-icon>
                        </v-card>
                      </v-col>
                      <v-col cols="1">
                        <v-icon
                          color="primary"
                          class="ml-2 mt-4"
                        >
                          mdi-file-outline
                        </v-icon>
                      </v-col>
                      <v-col
                        cols="7"
                      >
                        <v-card
                          dark
                          flat
                          height="100%"
                          class="rounded-l-0"
                        >
                          <div
                            class="grey--text text--darken-2 ml-3 mt-4"
                            style="font-size: 14px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                          >
                            {{ $t('documents|required_document') }}
                          </div>
                          <div
                            class="primary--text ml-3 mt-1"
                            style="font-size: 18px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                          >
                            {{ translateFileInputField(suggestion) }}
                          </div>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="2"
                      >
                        <v-card
                          class="rounded-l-0"
                          flat
                          height="100%"
                          color="lightBackground"
                          :style="`border-top-right-radius: 10px; border-bottom-right-radius: 10px; border: 1px solid ${$vuetify.theme.themes.light.background};`"
                        >
                          <v-card-text>
                            <v-row
                              justify="center"
                              no-gutters
                              class="pa-0"
                            >
                              <v-col
                                cols="12"
                                class="pa-0"
                              >
                                <v-icon
                                  :color="checkIfSuggestionTypeAvailableInDocuments(suggestion, workbench) ? 'success' : 'grey'"
                                  size="24"
                                  class="mb-2"
                                >
                                  mdi-check-circle
                                </v-icon>
                                <v-menu
                                  offset-y
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      icon
                                      size="24"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ icons.cog }}
                                    </v-icon>
                                  </template>
                                  <v-list>
                                    <v-list-item>
                                      <v-icon
                                        color="primary"
                                        class="mr-2"
                                      >
                                        mdi-link-off
                                      </v-icon>
                                      <v-list-item-title
                                        style="cursor: pointer;"
                                        @click="removeRequiredDocument(suggestion)"
                                      >
                                        {{ $t('actions|remove') }}
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </draggable>
              <div
                v-else
                class="center"
                style="font-size: 15px;"
              >
                {{ $t('expressions|no_actual_required_workbench_documents') }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                x-small
                rounded
                color="primary"
                @click="triggerAction('openRequiredDocumentDialog')"
              >
                <v-icon
                  x-small
                  class="mr-1"
                >
                  mdi-link
                </v-icon>
                {{ $t('actions|add_a_required_document') }}
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-list-item>
      </v-list-group>
    </v-list>

    <!-- TOOLBAR BOTTOM => ACTIONS -->
    <v-toolbar
      flat
      color="primary"
      class="bottom-element-workbench"
    >
      <!-- ADD NEW TEMPLATE -->
      <v-btn
        icon
        dark
        @click="addTemplatePage = true"
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>

      <v-menu
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-link
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in workbenchActionsMenu"
            :key="index"
            @click="triggerAction(item.action)"
          >
            <v-list-item-title
              style="white-space: normal;"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <div v-if="workbench">
      <v-row
        v-if="$route.name === 'WorkbenchViewMobile'"
        no-gutters
        class=""
      >
        <!-- Client workbench questionnaire section -->
        <v-col
          v-if="workbench && account.accountType === 'individual' || account.accountType === 'company'"
          :cols="colsRight"
        >
          <v-card
            flat
            outlined
            :style="`height: ${calculateCardHeight($vuetify.breakpoint.height, $vuetify.breakpoint.width).toString() + 'px'}`"
          >
            <v-toolbar
              dense
              flat
              height="45"
              color="lightBackground"
              style="position: sticky; top: 0px; z-index: 1;"
            >
              <v-subheader
                class="ml-n7"
              >
                {{ $t('questionnaire|questionnaires') }}
              </v-subheader>
            </v-toolbar>
            <div
              v-for="temp in workbench.templates"
              :key="temp._id"
              class="mx-2 my-4"
            >
              <QuestionnaireClientCard
                v-if="temp.questionnaire.sharedWithClient"
                :template="temp"
                :workbench="workbench"
                @open-answer-questionnaire="openAnswerDialog"
              />
            </div>
            <QuestionnaireAnswerDialog
              ref="QuestionnaireAnswerDialog"
              @updateQuestionnaire="updateQuestionnaire"
            />
          </v-card>
        </v-col>
      </v-row>
      <router-view />
      <FileView
        ref="fileView"
        :case-data="caseData"
        :in-case="true"
      />
    </div>
    <div
      v-if="loading"
      style="width: 100%; height: 100%;"
    >
      <v-overlay
        absolute
        opacity="0.68"
        :value="loading"
      >
        <v-progress-circular
          :size="50"
          color="white"
          indeterminate
        />
        <div style="position: relative; top: 15px; right: 40%;">
          <img
            src="@/assets/Lavirra_logo_SVG.svg"
            height="28px"
            width="auto"
          >
        </div>
      </v-overlay>
    </div>
  </v-container>
  <AddTemplateToWorkbenchMobile
    v-else-if="addTemplatePage"
    @close="addTemplatePage = false"
  />
  <AddDocumentsToWorkbenchMobile
    v-else-if="addDocumentsToCaseWorkbenchPage"
    :from-case-view="false"
    :from-workbench="true"
    :actual-case="caseData"
    :workbench="workbench"
    :case-documents="caseData.documents"
    @close="addDocumentsToCaseWorkbenchPage = false"
  />
  <AddRequiredDocumentsToWorkbenchMobile
    v-else-if="addRequiredDocumentsToWorkbenchMobilePage"
    :available-requested-documents="reqDocumentFields"
    @addFieldsToWorkbench="addFieldsToWorkbench"
    @close="addRequiredDocumentsToWorkbenchMobilePage = false"
  />
</template>

<script>
import { mdiTextBoxOutline } from '@mdi/js'
import generalMixin from '@/utils/generalMixin'
import caseWorkbenchMixin from '../../Cases/CaseMixins/caseWorkbenchMixin'
import AddTemplateToWorkbenchMobile from './AddTemplateToWorkbenchMobile.vue'
import AddDocumentsToWorkbenchMobile from './AddDocumentsToWorkbenchMobile.vue'
import AddRequiredDocumentsToWorkbenchMobile from './AddRequiredDocumentsToWorkbenchMobile.vue'

export default {
  components: {
    AddTemplateToWorkbenchMobile,
    AddDocumentsToWorkbenchMobile,
    AddRequiredDocumentsToWorkbenchMobile
  },
  mixins: [generalMixin, caseWorkbenchMixin],
  data () {
    return {
      addTemplatePage: false,
      addDocumentsToCaseWorkbenchPage: false,
      addRequiredDocumentsToWorkbenchMobilePage: false
    }
  },
  computed: {
    workbenchSectionsMobile () {
      return [
        {
          action: mdiTextBoxOutline,
          title: this.$t('cases|templates'),
          active: false,
          isTemplates: true,
          isWorkbenchDocuments: false,
          isRequiredWorkbenchDocuments: false
        },
        {
          action: 'mdi-file',
          title: this.$t('mixed|workbench_documents'),
          active: false,
          isTemplates: false,
          isWorkbenchDocuments: true,
          isRequiredWorkbenchDocuments: false
        },
        {
          action: 'mdi-file-outline',
          title: this.$t('mixed|required_workbench_documents'),
          active: false,
          isTemplates: false,
          isWorkbenchDocuments: false,
          isRequiredWorkbenchDocuments: true
        }
      ]
    },
    workbenchActionsMenu () {
      return [
        {
          title: this.$t('actions|assign_document'),
          action: 'assignWorkbenchDocument'
        },
        {
          title: this.$t('actions|add_a_required_document'),
          action: 'openRequiredDocumentDialog'
        }
      ]
    }
  },
  methods: {
    calculateCardHeight (height, width) {
      if (width >= 1450) return height - 375
      if (width < 1450 && width >= 1141) return height - 440
      if (width > 1048 && width < 1141) return height - 465
      if (width < 1048) return height - 485
      else return height - 440
    },
    triggerAction (action) {
      if (action && action === 'assignWorkbenchDocument') this.addDocumentsToCaseWorkbenchPage = true
      if (action && action === 'openRequiredDocumentDialog') this.addRequiredDocumentsToWorkbenchMobilePage = true
    }
  }
}
</script>

<style scoped>
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ghost {
    background-color: #d7d7d7;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
  }
  .toolbar {
    position: sticky;
    top: 48px;
    z-index: 1;
  }
  .bottom-element-workbench {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
</style>
